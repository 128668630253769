<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
    </span>

    <!-- <span class="float-md-right d-none d-md-block"> <b-link
      class="ml-25"
      href="https://www.e-yaz.com.tr/"
      target="_blank"
    >E-Yaz Yazılım ve Danışmanlık A.Ş</b-link>
    </span> -->
  </p>
</template>

<script>

export default {
  components: {
  },
}
</script>
