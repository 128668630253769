import * as authObj from '@/utils/auth'

export default function () {
  const userObject = authObj.getUserObject()
  const userGroup = userObject.UserGroupId
  if (userGroup === '1') {
    return [
      {
        title: 'Ana Sayfa',
        route: 'home',
        icon: 'HomeIcon',
      },
      { // Tanımlar
        title: 'Tanımlar',
        route: 'tanimlar',
        icon: 'SettingsIcon',
        children: [
          {
            title: 'Ülke Tanımları',
            route: 'ulke-tanimlari',
            icon: 'FlagIcon',
          },
          {
            title: 'Şehir Tanımları',
            route: 'sehir-tanimlari',
            icon: 'CompassIcon',
          },
          {
            title: 'İlçe Tanımları',
            route: 'town-tanimlari',
            icon: 'MapPinIcon',
          },
          {
            title: 'Mahalle Tanımları',
            route: 'mahalle-tanimlari',
            icon: 'FramerIcon',
          },
          {
            title: 'Üniversite Tanımları',
            route: 'universite-tanimlari',
            icon: 'UnderlineIcon',
          },
          {
            title: 'Yüksek Lisans Bölümleri',
            route: 'yuksek-lisans-bolumleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Lisans Bölümleri',
            route: 'lisans-bolumleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Ön Lisans Bölümleri',
            route: 'on-lisans-bolumleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Meslek Lisesi Bölümleri',
            route: 'meslek-lisesi-bolumleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Sektör Tanımları',
            route: 'sektor-tanimlari',
            icon: 'SlackIcon',
          },
          {
            title: 'Firma Tip Tanımları',
            route: 'firmatip-tanimlari',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Pozisyon Tanımları',
            route: 'pozisyon-tanimlari',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Yabancı Diller',
            route: 'yabanci-diller',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Yabancı Dil Sınavları',
            route: 'yabanci-dil-sinav',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Anlaşmazlık Nedenleri',
            route: 'anlasmazlik-nedenleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Personel Eğitimi',
            route: 'personel-egitimi',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Personel Bulma Yöntemi',
            route: 'personel-bulma-yontemi',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Sertifika Tanımları',
            route: 'sertifika-tanimlari',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Bilgisayar Yetenek Tanımları',
            route: 'bilgisayar-yetenek-tanimlari',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Sürücü Belgesi Tipleri',
            route: 'surucu-belgesi-tipleri',
            icon: 'CornerDownRightIcon',
          },
          {
            title: 'Çalışmaya Engel Hastalıklar',
            route: 'calismaya-engel-hastaliklar',
            icon: 'CornerDownRightIcon',
          },
          /* {
            title: 'Sosyal İmkanlar',
            route: 'sosyal-imkanlar',
            icon: 'CornerDownRightIcon',
          },
          */
          {
            title: 'Hobi Faktörleri',
            route: 'hobi-factor',
            icon: 'CornerDownRightIcon',
          },
        ],
      },
      { // Aday Görevlerim
        title: 'Aday Görevlerim',
        route: 'aday-gorev',
        icon: 'SlackIcon',
        children: [
          {
            title: 'Aday Kayıt',
            route: 'aday-kayit',
            params: { applicantId: -1 },
            icon: 'PackageIcon',
          },
          {
            title: 'Aday Listesi',
            route: 'aday-listesi',
            icon: 'UsersIcon',
          },
          {
            title: 'Aday Kayıt Tamamlama',
            route: 'aday-tamamla',
            icon: 'UserCheckIcon',
          },
        ],
      },
      { // İşveren Görevlerim
        title: 'İşveren Görevlerim',
        route: 'isveren-gorev',
        icon: 'GlobeIcon',
        children: [
          {
            title: 'İşveren Ekle',
            route: 'firma-kart',
            icon: 'GridIcon',
          },
          {
            title: 'İşveren Listesi',
            route: 'firma-kayitlari',
            icon: 'GridIcon',
          },
          {
            title: 'Kayıt Tamamlama',
            route: 'isveren-tamamla',
            icon: 'LifeBuoyIcon',
          },
          {
            title: 'Talep Oluşturma',
            route: 'firma-talepleri',
            icon: 'PackageIcon',
          },
          {
            title: 'Talep Listesi',
            route: 'talep-liste',
            icon: 'ListIcon',
          },
          {
            title: 'Talep Tamamlama',
            route: 'talep-tamamlama',
            icon: 'PackageIcon',
          },
        ],
      },
      { // Eşleştirme Görevlerim
        title: 'Eşleştirme Görevlerim',
        route: 'eslestirme-gorev',
        icon: 'FramerIcon',
        children: [
          {
            title: 'Eşleştirilecek Talepler',
            route: 'firm-request',
            icon: 'GridIcon',
          },
        ],
      },
      { // Yönlendirme Görevlerim
        title: 'Yönlendirme Görevlerim',
        route: 'yonlendirme-gorev',
        icon: 'WindIcon',
        children: [
          {
            title: 'Yönlendirilecek Talepler',
            route: 'talep-yonlendir',
            icon: 'GridIcon',
          },
        ],
      },
      { // Raporlar
        title: 'Raporlarım',
        route: 'system-report',
        icon: 'SlackIcon',
        children: [
          {
            title: 'Güncel Aday Listesi ',
            route: 'rapor-aday',
            icon: 'UsersIcon',
          },
          {
            title: 'İstihdam Edilmiş Adaylar',
            route: 'aday-istihdam',
            icon: 'UserCheckIcon',
          },
          {
            title: 'Bekleyen Adaylar',
            route: 'aday-bekler',
            icon: 'UserCheckIcon',
          },
          {
            title: 'Olumsuz Adaylar',
            route: 'aday-olumsuz',
            icon: 'UserCheckIcon',
          },
          {
            title: 'Aktif Firma Listesi',
            route: 'firma-aktif',
            icon: 'UserCheckIcon',
          },
          {
            title: 'Aktif Talep Listesi',
            route: 'talep-aktif',
            icon: 'UserCheckIcon',
          },
        ],
      },
    ]
  }
  if (userGroup === '2') {
    return [
      {
        title: 'Ana Sayfa',
        route: 'home',
        icon: 'HomeIcon',
      },
      // {
      //   title: 'Tanımlar',
      //   route: 'tanimlar',
      //   icon: 'SettingsIcon',
      //   children: [
      //     {
      //       title: 'Ülke Tanımları',
      //       route: 'ulke-tanimlari',
      //       icon: 'FlagIcon',
      //     },
      //     {
      //       title: 'Şehir Tanımları',
      //       route: 'sehir-tanimlari',
      //       icon: 'CompassIcon',
      //     },
      //     {
      //       title: 'İlçe Tanımları',
      //       route: 'town-tanimlari',
      //       icon: 'MapPinIcon',
      //     },
      //     {
      //       title: 'Mahalle Tanımları',
      //       route: 'mahalle-tanimlari',
      //       icon: 'FramerIcon',
      //     },
      //     {
      //       title: 'Üniversite Tanımları',
      //       route: 'universite-tanimlari',
      //       icon: 'UnderlineIcon',
      //     },
      //     {
      //       title: 'Yüksek Lisans Bölümleri',
      //       route: 'yuksek-lisans-bolumleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Lisans Bölümleri',
      //       route: 'lisans-bolumleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Ön Lisans Bölümleri',
      //       route: 'on-lisans-bolumleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Meslek Lisesi Bölümleri',
      //       route: 'meslek-lisesi-bolumleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Sektör Tanımları',
      //       route: 'sektor-tanimlari',
      //       icon: 'SlackIcon',
      //     },
      //     {
      //       title: 'Firma Tip Tanımları',
      //       route: 'firmatip-tanimlari',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Pozisyon Tanımları',
      //       route: 'pozisyon-tanimlari',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Yabancı Diller',
      //       route: 'yabanci-diller',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Yabancı Dil Sınavları',
      //       route: 'yabanci-dil-sinav',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Anlaşmazlık Nedenleri',
      //       route: 'anlasmazlik-nedenleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Personel Eğitimi',
      //       route: 'personel-egitimi',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Personel Bulma Yöntemi',
      //       route: 'personel-bulma-yontemi',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Sertifika Tanımları',
      //       route: 'sertifika-tanimlari',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Bilgisayar Yetenek Tanımları',
      //       route: 'bilgisayar-yetenek-tanimlari',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Sürücü Belgesi Tipleri',
      //       route: 'surucu-belgesi-tipleri',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Çalışmaya Engel Hastalıklar',
      //       route: 'calismaya-engel-hastaliklar',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     {
      //       title: 'Sosyal İmkanlar',
      //       route: 'sosyal-imkanlar',
      //       icon: 'CornerDownRightIcon',
      //     },
      //     // This is array of menu items or menu groups
      //     // NOTE: You can't use menu header as children
      //   ],
      // },
      {
        title: 'Aday Görevlerim',
        route: 'aday-gorev',
        icon: 'SlackIcon',
        children: [
          {
            title: 'Aday Kayıt',
            route: 'aday-kayit',
            params: { applicantId: -1 },
            icon: 'PackageIcon',
          },
          {
            title: 'Aday Listesi',
            route: 'aday-listesi',
            icon: 'UsersIcon',
          },
          {
            title: 'Aday Kayıt Tamamlama',
            route: 'aday-tamamla',
            icon: 'UserCheckIcon',
          },
        ],
      },
      // {
      //   title: 'İşveren Görevlerim',
      //   route: 'isveren-gorev',
      //   icon: 'GlobeIcon',
      //   children: [
      //     {
      //       title: 'İşveren Ekle',
      //       route: 'firma-kart',
      //       icon: 'GridIcon',
      //     },
      //     {
      //       title: 'İşveren Listesi',
      //       route: 'firma-kayitlari',
      //       icon: 'GridIcon',
      //     },
      //     {
      //       title: 'Kayıt Tamamlama',
      //       route: 'isveren-tamamla',
      //       icon: 'LifeBuoyIcon',
      //     },
      //     {
      //       title: 'Talepi Oluşturma',
      //       route: 'firma-talepleri',
      //       icon: 'PackageIcon',
      //     },
      //     {
      //       title: 'Talep Tamamlama',
      //       route: 'talep-tamamlama',
      //       icon: 'PackageIcon',
      //     },
      //   ],
      // },
      // {
      //   title: 'Eşleştirme Görevlerim',
      //   route: 'eslestirme-gorev',
      //   icon: 'FramerIcon',
      //   children: [
      //     {
      //       title: 'Eşleştirilecek Talepler',
      //       route: 'firm-request',
      //       icon: 'GridIcon',
      //     },
      //   ],
      // },
      // {
      //   title: 'Yönlendirme Görevlerim',
      //   route: 'yonlendirme-gorev',
      //   icon: 'WindIcon',
      //   children: [
      //     {
      //       title: 'Yönlendirilecek Talepler',
      //       route: 'talep-yonlendir',
      //       icon: 'GridIcon',
      //     },
      //   ],
      // },
    ]
  }
  if (userGroup === '3') {
    return [
      {
        title: 'Ana Sayfa',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'İşveren İşlemlerim',
        route: 'second-page',
        icon: 'FileIcon',
        children: [
          {
            title: 'İşveren Bilgilerim',
            route: 'firma-bilgilerim',
            icon: 'ServerIcon',
          },
          {
            title: 'İş İlanlarım',
            route: 'firma-taleplerim',
            icon: 'PackageIcon',
          },
        ],
      },
      {
        title: 'Raporlarım',
        route: 'second-page',
        icon: 'FileIcon',
        children: [
          {
            title: 'İşe Aldıklarım',
            route: 'firma-ise-aldiklarim',
            icon: 'ServerIcon',
          },
          // {
          //   title: 'Bekleyen Adaylar',
          //   route: 'firma-bekleyen-adaylar',
          //   icon: 'PackageIcon',
          // },
          {
            title: 'Olumsuz Adaylar',
            route: 'firma-olumsuz-adaylar',
            icon: 'PackageIcon',
          },
          {
            title: 'Taleplerim',
            route: 'firma-talep-listesi',
            icon: 'PackageIcon',
          },
        ],
      },
      {
        title: 'Şifre Değiştir',
        route: 'second-page',
        icon: 'FileIcon',
        children: [
          {
            title: 'Şifre Değiştir',
            route: 'sifre-degistir',
            icon: 'ServerIcon',
          },
        ],
      },
    ]
  }
  if (userGroup === '4') {
    return [
      {
        title: 'Ana Sayfa',
        route: 'home',
        icon: 'HomeIcon',
      },
      {
        title: 'Aday İşlemlerim',
        route: 'second-page',
        icon: 'FileIcon',
        children: [
          {
            title: 'Kayıt Bilgilerim',
            route: 'aday-bilgilerim',
            icon: 'PackageIcon',
          },
        ],
      },
    ]
  }

  return [{
    title: 'Ana Sayfa',
    route: 'home',
    icon: 'HomeIcon',
  }]
}
